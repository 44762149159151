@mixin xl-res {
    @media screen and ( max-width: 767px) and ( min-width: 0) {
        @content;
    }
}

@mixin sm-res {
    @media screen and (max-width: 991px) and (min-width: 768px) {
        @content;
    }
}

@mixin md-res {
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        @content;
    }
}

@mixin lg-res {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
}

@mixin prefixer($property, $value, $prefixes :()) {
    @each $prefix in $prefixes {
        #{"-" + $prefix + "-" + $property}: $value;
    }
    #{$property}: $value;
}

@mixin keyFrameanimate($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}


/* @include keyFrameanimate (pulse) {
   code here
 }*/

@mixin optional-at-root($color) {
    @at-root #{if(not &, $color, selector-append(&, $color))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }
    @include optional-at-root(':-moz-placeholder') {
        @content;
    }
    @include optional-at-root('::-moz-placeholder') {
        @content;
    }
    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

@mixin opacity($value-opacity) {
    zoom: 1;
    $opacity-ie: $value-opacity * 100;
    filter: alpha(opacity=$opacity-ie);
    opacity: $value-opacity;
}