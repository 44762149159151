@font-face {
  font-family: 'OpenSans-Regular';
  src: url('../../../fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../../../fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Droid Arabic Kufi';
  src: url('../../../fonts/Droid_Arabic_Kufi.ttf');
}
@font-face {
  font-family: 'Droid Arabic Kufi Regular';
  src: url('../../../fonts/Droid-Arabic-Kufi-Regular.ttf');
}

@font-face {
  font-family: 'Droid Arabic Kufi Bold';
  src: url('../../../fonts/Droid_Arabic_Kufi_Bold.ttf');
}

@font-face {
  font-family: 'icomoon';
  src: url('../../../fonts/icomoon.eot?wyhe6t');
  src: url('../../../fonts/icomoon.eot?wyhe6t#iefix') format('embedded-opentype'),
    url('../../../fonts/icomoon.ttf?wyhe6t') format('truetype'),
    url('../../../fonts/icomoon.woff?wyhe6t') format('woff'),
    url('../../../fonts/icomoon.svg?wyhe6t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.3rem;
}

.icon-blocks:before {
  content: "\e905";
}

.icon-list:before {
  content: "\e906";
}

.icon-Bag:before {
  content: "\e907";
}

.icon-search:before {
  content: "\e908";
}

.icon-bar:before {
  content: "\e904";
}

.icon-exclamination:before {
  content: "\e903";
}

.icon-close:before {
  content: "\e900";
}

.icon-clock:before {
  content: "\e901";
}

.icon-car:before {
  content: "\e902";
}