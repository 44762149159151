// color & bgcolor

:root {


    --firstColor: #000000;
    --secondColor: #E6AB01;
    --thirdColor: #707070;
    --fourthColor: #BAB9C0;
    --fifthColor: #EDEDED;
    --danger: #C62F2F;


    --grey-color: #8A888D;
    --driver-track-color: #9DCA55;
    --track-shadow: #D3D1D4;
    --border-color: #B1ADB4;
    --order-number: #7C777E;
    --main-bg-color: #817683;
    --main-font-color: #fff;


    --mainfontfamilyEn: 'OpenSans-Regular';
    --mainfontfamilyboldEn: 'OpenSans-Bold';
 
    --mainfontfamilyAr: 'Droid Arabic Kufi Regular';
    --mainfontfamilyboldAr: 'Droid Arabic Kufi Bold';

    

    --direction: ltr;
    --opposite-direction: rtll;
    --start-direction: left;
    --end-direction: right;



    --paddingSections: 4rem 0;
    --paddingSectionsPlus: 5rem 0;

    --mainSizeFont: 1rem;
    --wrapperWidth: 620px;

    // media query variables
    --maxMobile: "(max-width:575px)"; // from 0 to max mobile   // xs
    --minSmall: "(min-width:576px)"; // from small to up        // sm
    --maxSmall: "(max-width:767px)"; // from 0 to max small     // sm
    --minMedium: "(min-width:768px)"; // from medium to up        // medium
    --maxMedium: "(max-width:991px)"; // from 0 to max medium        // medium
    --minLarge: "(min-width:992px)"; // from large to up        // large
    --minXlarge: "(min-width:1200px)"; // from xlarge to up        // xl

}